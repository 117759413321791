<template>
  <div>
    <en-table-layout :tips="false" :toolbar="false" :tableData="tableData.data" :loading="loading">
      <!-- 表格数据 -->
      <template slot="table-columns">
        <el-table-column prop="wx_openid" label="openID" width="180" />
        <el-table-column prop="mobile" label="客户手机号" width="180" />
        <el-table-column prop="nick_name" label="微信昵称" width="180" />
        <el-table-column label="绑定时间" width="180">
          <template slot-scope="scope">{{ scope.row.bind_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
        </el-table-column>
        <el-table-column label="累计消费金额" width="180">
          <template
            slot-scope="scope"
          >{{ scope.row.cumulative_money?scope.row.cumulative_money:0| unitPrice("￥")}}</template>
        </el-table-column>
        <el-table-column prop="order_total" label="订单数量" width="180" />
        <el-table-column prop="sex" label="性别" width="180">
          <template slot-scope="scope">{{ scope.row.sex==='1'?'男':'女'}}</template>
        </el-table-column>

        <el-table-column label="操作" fixed="right" width="180">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="handleAdd(scope.row)">恢复</el-button>
          </template>
        </el-table-column>
      </template>
      <!-- 表格分页 -->
      <el-pagination
        v-if="tableData"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
import * as API_Member from "@/api/member";
// import { RegExp } from "bms-common/ui-utils";

export default {
  name: "customerBlacklist",

  data() {
    return {
      /** 列表loading状态 */
      loading: false,

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 10,
        disabled: -1
      },

      /** 列表数据 */
      tableData: ""
    };
  },

  mounted() {
    this.GET_MemberList();
  },
  beforeRouteUpdate(to, from, next) {
    this.GET_MemberList();
    next();
  },
  methods: {
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_MemberList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_MemberList();
    },
    /** 黑名单恢复 */
    handleAdd(row) {
      this.$confirm("确认要恢复该客户吗？", "提示", {
        type: "warning",
        cancelButtonClass: "btn-default-dialog-no",
        confirmButtonClass: "btn-default-dialog-yes"
      })
        .then(() => {
          API_Member.post_members(row.member_id)
            .then(res => {
              this.$message.success("操作成功！");
              this.GET_MemberList();
            })
            .catch(() => {});
        })
        .catch(() => {});
    },

    /** 获取黑名单列表 */
    GET_MemberList() {
      this.loading = true;
      const { params } = this;
      Object.keys(params).forEach(key => {
        if (!params[key] && params[key] !== 0) {
          delete params[key];
        }
      });
      API_Member.getPageList(params)
        .then(response => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style type="text/scss" lang="scss" scoped>
.show-pwd {
  position: absolute;
  top: 0;
  right: 10px;
  cursor: pointer;
}

.el-date-editor.el-input {
  width: 180px;
}

::v-deep .form-item-sex .el-form-item__content {
  width: 180px;
}

::v-deep .form-item-region .el-form-item__content {
  min-width: 180px;
}
::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 30px;
}
div.toolbar-btns {
  display: contents;
}
</style>
